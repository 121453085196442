import CONFIG from '../config.js';
import { logger } from './log.service';

export const GAtracker = {
  record,
  init
};

const category = CONFIG.analytics.ga.category;

function record(action, label) {
  let ts = Math.floor(Date.now() / 1000);
  let tempVideoId = 'video-id-' + ts;
  if (
    label === null &&
    window.location.hostname === 'localhost'
  ) {
    label = tempVideoId;
  }else if (label === null && window.name !== ''){
    label = window.name + '_' + tempVideoId;
  }

  logger.log({
    type: 'event',
    msg: (category + '\n' + action + '\n' + label)
  });

  window.ga('send', 'event', category, action, label);
}

function init() {
  window.ga('create', CONFIG.analytics.ga.id, 'auto');
}
