<template>
  <div id="app">
    <si-spinner
      v-if="loadingSpinner.show"
    ></si-spinner>

    <video-player
      v-if="ready"
      :video="video"
      :type="type"
      :thumbnails="thumbnails"
    ></video-player>
  </div>
</template>

<script>
  import CONFIG from './config';
  import LoadingSpinner from './services/loading-spinner.service';
  import Spinner from './components/Spinner.vue';
  import VideoPlayer from './components/VideoPlayer.vue';
  import SBService from './services/sb.service';
  import bus from './services/bus.service';
  import { logger } from './services/log.service';

  export default {

    name: 'app',

    data(){

      return {
        loadingSpinner: LoadingSpinner.spinner,
        type: CONFIG.type,
        video: null,
        ready: false,
        returned: false,
        thumbnails: false
      };
    },

    mounted(){
      const self = this;

      self.addListener();

      if(
        CONFIG.video.sb.site_id !== null &&
        (
          CONFIG.video.sb.playlist_id !== null ||
          CONFIG.video.sb.video_id !== null
        )
      ){
        logger.log('Setting Video data from SB API...');

        SBService.init().then(data => {
          self.type = CONFIG.type = data.type;
          CONFIG.video.playlist = data.videos;
          self.thumbnails = CONFIG.video.sb.thumbnails;
          self.video = CONFIG.video;
          self.ready = true;
        });
      }else{
        self.video = CONFIG.video;
        if(self.video.id === null){
          self.video.id = 'video-'+Date.now();
        }
        self.ready = true;
      }
    },

    methods: {

      respondMsg(e){
        if (
          e.data.source === 'external' &&
          e.data.type === 'command'
        ) {
          logger.log('Received Message: ' + e.data.command, e.data.data);

          switch(e.data.command){
            case 'postId':
              CONFIG.video.ci = e.data.data.ci;
              break;
            case 'video':
              if (e.data.data.action === 'pause') {
                bus.$emit('pauseVideo', true);
              }
              break;
            case 'visibility':
            default:
              CONFIG.video.visible = e.data.data.visible;
              bus.$emit('onVPVisibility', e.data.data.visible);
              break;
          }
        }
      },

      addListener(){
        if (window.addEventListener) {
          window.addEventListener('message', this.respondMsg.bind(this), false);
        } else {
          window.attachEvent('onmessage', this.respondMsg.bind(this));
        }

        parent.postMessage({
          source: window.name,
          target: 'external',
          type: 'command',
          data: {
            ready: true
          }
        }, '*');
      }
    },

    components: {
      VideoPlayer,
      siSpinner: Spinner
    }
  }
</script>
