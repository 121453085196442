<template>
  <video
    :class="{
      'video-js vjs-big-play-centered si-videoplayer__video vjs-16-9': true,
      'si-videoplayer__video--mobile': (isMobile.any() !== null)
    }"
    :id="videoJsId"
    ref="siPlayer"
    preload="auto"
    :width="videoSize.width"
    :height="videoSize.height"
  >
    <p class="vjs-no-js">
      To view this video please enable JavaScript, and consider upgrading to a
      web browser that
      <a href="http://videojs.com/html5-video-support/" target="_blank">
        supports HTML5 video
      </a>
    </p>
  </video>
</template>

<script>
  import CONFIG from '../config';
  import _ from 'lodash';
  import canAutoPlay from 'can-autoplay';
  import {TrackEvent, EVENTS, EVENT_ATTRIBUTES} from '../services/track-event.service';
  import bus from '../services/bus.service';
  import { logger } from '../services/log.service';

  export default {

    name: 'HTML5Video',

    props: {
      'videoSrc' : {type: String},
      'videoSize': {type: Object},
      'videoProps': {type: Object},
      'videoCallbacks': {type: Object},
      'videoClickout' : {type: String},
      'videoID': {type: String},
      'ad': {type: Object},
      'visible': {type: Boolean}
    },

    data() {

      return {
        videoJsId: 'si-video-'+Date.now(),
        videoPlayer: '',
        videoDuration: '',
        previousProgress: '',
        previousMuted: true,
        ready: false,
        defaultOptions: {
          playsinline: true,
          autoplay: false,
          muted: true,
          controls: true,
          playbackRates: [1, 1.5, 2, 3],
          poster: false,
          loop: false
        },
        options: {},
        autoplayAllowed: false,
        autoplayRequiresMute: false,
        startEvent: 'click',
        isMobile: {
          Android() {
            return navigator.userAgent.match(/Android/i);
          },
          BlackBerry() {
            return navigator.userAgent.match(/BlackBerry/i);
          },
          iOS() {
            return navigator.userAgent.match(/iPhone|iPad|iPod/i);
          },
          Opera() {
            return navigator.userAgent.match(/Opera Mini/i);
          },
          Windows() {
            return navigator.userAgent.match(/IEMobile/i);
          },
          any() {
            return (
              this.Android() || this.BlackBerry() ||
              this.iOS() || this.Opera() || this.Windows()
            );
          }
        },
        adsErrors: 0
      };
    },

    mounted() {
      let self = this;
      let tempSrc = 'https://cdn.springboardplatform.com/storage/SI-General/conversion/1537851.mp4';
      let src = self.videoSrc;

      if(self.videoSrc === null){

        logger.log({
          msg: 'No video src was received!',
          type: 'error'
        });
        if(window.location.hostname === 'localhost'){
          src = tempSrc;
          logger.log('[DEV] Running with Video Placeholder');
        }
        return;
      }

      self.options = _.assign(self.defaultOptions, self.videoProps, {src});
      if(!self.visible){
        self.options.autoplay = false;
      }
      self.checkUnmutedAutoplaySupport();
    },

    methods: {

      checkUnmutedAutoplaySupport() {
        let self = this;

        canAutoPlay.video({
            timeout: 100,
            muted: false
          }).then(({result, error}) => {
            if(result === false) {
              // Unmuted autoplay is not allowed.
              self.checkMutedAutoplaySupport();
            } else {
              // Unmuted autoplay is allowed.
              self.autoplayAllowed = true;
              self.autoplayRequiresMute = false;
              self.initPlayer();
            }
          });
      },

      checkMutedAutoplaySupport() {
        let self = this;

        canAutoPlay.video({
          timeout: 100,
          muted: true
        }).then(({result, error}) => {
            if(result === false) {
              // Muted autoplay is not allowed.
              self.autoplayAllowed = false;
              self.autoplayRequiresMute = false;
            } else {
              // Muted autoplay is allowed.
              self.autoplayAllowed = true;
              self.autoplayRequiresMute = true;
            }
            self.initPlayer();
        });
      },

      initAdDisplayContainer() {
        this.videoPlayer.ima.initializeAdDisplayContainer();
        this.videoPlayer.removeEventListener(this.startEvent, this.initAdDisplayContainer.bind(this));
      },

      initPlayer() {
        let self = this;

        self.videoPlayer = window.videojs(self.videoJsId, self.options, function(){
          let player = this;

          player.src({
            type: 'video/mp4',
            src: self.options.src
          });

          if(
            !_.isUndefined(self.options.currentTime) &&
            _.isNumber(self.options.currentTime)
          ){
            player.currentTime(self.options.currentTime);
          }

          function check(){
            var state = document.fullscreen || document.mozFullScreen || document.webkitIsFullScreen || document.webkitDisplayingFullscreen || document.msFullscreenElement != null;
            var element = document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement || document.msFullscreenElement;
            var event = state ? 'FullscreenOn' : 'FullscreenOff';

            if (
              event === 'FullscreenOn' &&
              !_.isUndefined(element)
            ) {
              self.onVideoFullscreen();
            }else{
              document.body.className = document.body.className.replace(/(^|\s)is-fullscreen\b/g, '');
            }
          }
          document.addEventListener('fullscreenchange', check);
          document.addEventListener('mozfullscreenchange', check);
          document.addEventListener('webkitfullscreenchange', check);
          document.addEventListener('MSFullscreenChange', check);

          // Add Events Manually
          player.on('canplaythrough', self.onVideoReady);
          player.on('timeupdate', self.reportQuartiles);
          player.on('play', self.onVideoPlay);
          player.on('pause', self.onVideoPaused);
          player.on('seeking', self.onVideoSeeking);
          player.on('seeked', self.onVideoSeeked);
          player.on('volumechange', self.onVolumeChanged);
          player.on('mouseover', self.onVideoMouseOver);
          player.on('click', self.onVideoClick);
          player.on('ended', self.onVideoEnd);
          player.on('adplaying', e => {
            self.adsErrors = 0;
          });
          player.on('adserror', e => {
            self.adsErrors++;
            if (!_.isUndefined(e.data.AdError)) {
              logger.log({
                msg: 'Ad Error',
                type: 'error',
                data: e.data.AdError
              });
            }
            // if (self.adsErrors >= 2) {
            //   self.onVideoReady();
            //   self.adsErrors = 0;
            // } else {
            //   self.runAdBreak();
            // }
          });
        });

        bus.$on('pauseVideo', () => {
          self.videoPlayer.pause();
        });

        bus.$on('onVPVisibility', visible => {
          if (
            (visible && !self.videoPlayer.paused()) ||
            (visible && self.videoProps.autoplay)
          ){
            self.videoPlayer.play();
          } else if (!visible){
            self.videoPlayer.pause();
          }
        });
        self.videoPlayer.runAdBreak = self.runAdBreak;
        self.initAd();
      },

      initAd(){
        const self = this;

        // https://github.com/googleads/videojs-ima
        if(
          !_.isUndefined(self.videoPlayer.ima) &&
          self.ad.iu !== null &&
          self.ad.cust_params !== null
        ) {
          let tag = self.ad.tag + '&iu=' + self.ad.iu + '&cust_params=' + self.ad.cust_params + '&correlator=' + (Math.floor(Date.now() / 1000));

          self.videoPlayer.ima({
            id: self.videoJsId,
            adTagUrl: tag,
            adsManagerLoadedCallback: self.initImaEvents.bind(self),
            vastLoadTimeout: 10000,
            numRedirects: 2
          });

          logger.log('Running tag:\n' + tag);

          if (!self.options.autoplay) {
            if (navigator.userAgent.match(/iPhone/i) ||
              navigator.userAgent.match(/iPad/i) ||
              navigator.userAgent.match(/Android/i)) {
              self.startEvent = 'touchend';
            }
            self.videoPlayer.one(self.startEvent, self.initAdDisplayContainer.bind(self));
          }
        }else{
          logger.log({
            msg: 'No "iu" or "cust_params" found to run the ad tag!',
            type: 'warning'
          });
        }
      },

      initImaEvents(e) {
        // GOOGLE EVENTS
        // https://github.com/googleads/videojs-ima/blob/master/examples/advanced/ads.js#L77
        // google.ima.AdEvent.Type.ALL_ADS_COMPLETED,
        // google.ima.AdEvent.Type.CLICK,
        // google.ima.AdEvent.Type.COMPLETE,
        // google.ima.AdEvent.Type.FIRST_QUARTILE,
        // google.ima.AdEvent.Type.LOADED,
        // google.ima.AdEvent.Type.MIDPOINT,
        // google.ima.AdEvent.Type.PAUSED,
        // google.ima.AdEvent.Type.RESUMED,
        // google.ima.AdEvent.Type.STARTED,
        // google.ima.AdEvent.Type.THIRD_QUARTILE

        this.videoPlayer.ima.addEventListener(window.google.ima.AdEvent.Type.STARTED, () => {
          this.videoPlayer.ima.isPlaying = true;
        });
        this.videoPlayer.ima.addEventListener(window.google.ima.AdEvent.Type.COMPLETE, () => {
          this.videoPlayer.ima.isPlaying = false;
          this.onVideoReady();
        });
      },

      runAdBreak(cb) {
        const self = this;

        // https://github.com/googleads/videojs-ima
        if(
          !_.isUndefined(self.videoPlayer.ima) &&
          self.ad.iu !== null &&
          self.ad.cust_params !== null
        ){
          let tag = self.ad.tag + '&iu=' + self.ad.iu + '&cust_params=' + self.ad.cust_params + '&correlator=' + (Math.floor(Date.now() / 1000));
          self.videoPlayer.ima.changeAdTag(tag);
          self.videoPlayer.ima.requestAds();
          self.videoPlayer.play();

          logger.log('Running adbreak with tag:\n' + tag);

          if (
           !_.isUndefined(cb) &&
            _.isFunction(cb)
          ) {
            cb();
          }
        }else{
          logger.log({
            msg: 'No "iu" or "cust_params" found to run the ad tag!',
            type: 'warning'
          });
        }
      },

      onVideoReady(e) {
        this.videoDuration = this.videoPlayer.duration();

        if (
          !_.isUndefined(this.videoCallbacks) &&
          this.videoCallbacks.onVideoReady
        ) {
          this.videoCallbacks.onVideoReady(this.videoPlayer);
        }
      },

      reportQuartiles(){
        let self = this;
        let current = self.videoPlayer.currentTime();
        let pst = (current * 100) / (self.videoDuration);

        if(pst == 'Infinity'){
          return;
        }

        if( pst >= 25 && self.previousProgress < 25 ){
          TrackEvent.record(EVENTS.Video, EVENT_ATTRIBUTES.Video.VP_25, self.videoID, self.videoID);
        }

        if( pst >= 50 && self.previousProgress < 50 ){
          TrackEvent.record(EVENTS.Video, EVENT_ATTRIBUTES.Video.VP_50, self.videoID, self.videoID);
        }

        if( pst >= 75 && self.previousProgress < 75 ){
          TrackEvent.record(EVENTS.Video, EVENT_ATTRIBUTES.Video.VP_75, self.videoID, self.videoID);
        }

        if( pst >= 90 && self.previousProgress < 90 ){
          self.$emit('quartile75Reached', self.videoPlayer);
        }

        if( pst >= 100 && self.previousProgress < 100 ){
          TrackEvent.record(EVENTS.Video, EVENT_ATTRIBUTES.Video.Finish, self.videoID, self.videoID);
        }

        // video duration is in seconds
        // if video takes more than 1 minute let's show an ad break
        /* We no longer need to run the ad break, but i am commmenting this in case we need this later
        if (this.videoDuration > 60 && this.videoDuration > 80) {

          if( pst >= 50 && self.previousProgress < 50 ){
            self.runAdBreak();
          }
        }
        */

        self.previousProgress = pst;
      },

      onVolumeChanged(e){

        if(this.videoPlayer.muted()){
          TrackEvent.record(EVENTS.Video, EVENT_ATTRIBUTES.Video.Mute, this.videoID, this.videoID);
        }else {

          if(this.previousMuted ) {
            TrackEvent.record(EVENTS.Video, EVENT_ATTRIBUTES.Video.Unmute, this.videoID, this.videoID);
          }
        }

        this.previousMuted = this.videoPlayer.muted();
      },

      onVideoPlay(e){
        this.previousMuted = this.videoPlayer.muted();

        TrackEvent.record(EVENTS.Video, EVENT_ATTRIBUTES.Video.Start, this.videoID, this.videoID);
      },

      onVideoPaused(e){
        TrackEvent.record(EVENTS.Video, EVENT_ATTRIBUTES.Video.Pause, this.videoID, this.videoID);
      },

      onVideoSeeking(e){
      },

      onVideoSeeked(e){
        TrackEvent.record(EVENTS.Video, EVENT_ATTRIBUTES.Video.Seek, this.videoID, this.videoID);
      },

      onVideoEnd(e){

        if (
          !_.isUndefined(this.videoCallbacks) &&
          this.videoCallbacks.onVideoEnded
        ) {
          this.videoCallbacks.onVideoEnded(this.videoPlayer);
        }
      },

      onVideoFullscreen(e){
        TrackEvent.record(EVENTS.Video, EVENT_ATTRIBUTES.Video.Fullscreen, this.videoID, this.videoID);
      },

      onVideoMouseOver(e){
        let self = this;

        setTimeout(() => {
          TrackEvent.record(EVENTS.Video, EVENT_ATTRIBUTES.Video.Hover, self.videoID, self.videoID);
        }, 3000);
      },

      onVideoClick(e) {
        if(e.target.nodeName !== 'VIDEO'){
          return;
        }
        if(
          this.videoClickout !== '' &&
          !_.isUndefined(this.videoClickout) &&
          this.videoClickout !== null
        ) {
          let clickout = this.videoClickout;

          TrackEvent.record(EVENTS.Video, EVENT_ATTRIBUTES.Video.Click, this.videoID);
          window.open(clickout, '_blank');
        }
      }
    }
  }
</script>
