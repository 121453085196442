<template>
  <div class="si-videoplayer" v-if="video">
      <div class="si-videoplayer__container">
        <si-video
          ref="videoPlayer"
          v-if="currVideo && ready"
          :videoSize="video.size"
          :videoProps="props"
          :videoCallbacks="videoAPI"
          :videoID="currVideo.id"
          :videoClickout="currVideo.clickout"
          :videoSrc="currVideo.props.src"
          :ad="video.ad"
          :visible="video.visible"
          @quartile75Reached="onQuartile75Reached"
        />
        <si-nextup
          v-if="type === 'playlist' && getNextVideo() !== null"
          v-show="showNext"
          :next="getNextVideo()"
          @onCloseNextUp="showNext = false"
          @onNextClicked="nextClicked"
        ></si-nextup>
      </div>
      <si-thumbnails
        v-if="(type === 'playlist' && thumbnails)"
        :current="currVideo"
        :videos="video.playlist"
        @onThumbnailClicked="onThumbnailClicked"
      ></si-thumbnails>
  </div>
</template>

<script>
  import _ from 'lodash';
  import HTML5Video from './HTML5Video.vue';
  import Thumbnails from './Thumbnails.vue';
  import NextUp from './NextUp.vue';
  import LoadingSpinner from '../services/loading-spinner.service';
  import { logger } from '../services/log.service';

  export default {

    name: 'VideoPlayer',

    props: [
      'video', 'type',
      'thumbnails'
    ],

    data() {

      return {
        index: 0,
        currVideo: this.video.playlist.length >= 1 ? this.video.playlist[0] : {
            props: this.video.props
        },
        videoAPI: {
          onVideoReady: this.onVideoReady,
          onVideoEnded: this.onVideoFinish
        },
        player: null,
        props: {},
        ready: false,
        showNext: false
      };
    },

    mounted() {
        this.props = _.assign(this.video.props, this.currVideo.props);

      setTimeout(() => {
        this.assignVideoId();
        this.ready = true;
      });
    },

    methods: {

      assignVideoId() {
        if (this.video.ci !== '') {
          this.currVideo.id = this.video.ci + '_' + this.currVideo.id;
        }
      },

      getNextVideo() {
        let nextIndex = this.index + 1;

        if (
          this.video.playlist[nextIndex] &&
          (this.video.playlist[nextIndex] !== _.last(this.video.playlist)))
        {

          return {
            video: this.video.playlist[nextIndex],
            index: nextIndex
          };
        }

        return null;
      },

      onVideoFinish(player) {
        let self = this;
        self.showNext = false;
        let next = self.getNextVideo();

        if (self.type === 'playlist') {

          if (next !== null){

            self.player.runAdBreak(() => {
              self.assignVideoId();
              self.changeVideo(next.index, next.video);
            });
          }else{
            logger.log('End of playlist reached');
          }
        }
      },

      changeVideo(i, vid) {
        let self = this;
        self.showNext = false;
        self.index = i;
        self.currVideo = vid;

        self.player.src({
          type: 'video/mp4',
          src: self.currVideo.props.src
        });
        self.player.poster(self.currVideo.props.poster);
        self.player.load();
        self.player.play();
        LoadingSpinner.hide();
      },

      onVideoReady(player) {
        if(player !== null){
          this.player = player;
        }

        setTimeout(() => {
          this.$emit('playerReady', true);
        });
      },

      onThumbnailClicked(e) {
        const self = this;

        if(
          typeof e.e === 'undefined' ||
          self.player === null ||
          (
            self.player !== null &&
            typeof this.player.ima !== 'undefined' &&
            self.player.ima.isPlaying
          )
        ){
          return;
        }
        LoadingSpinner.show();

        let selected = _.findIndex(self.video.playlist, i => {
          return i.id === e.v.id;
        });

        self.changeVideo(selected, e.v);
      },

      nextClicked(next) {
        this.showNext = false;
        this.changeVideo(next.index, next.video);
      },

      onQuartile75Reached() {
        this.showNext = true;
      }
    },

    components: {
      siVideo: HTML5Video,
      siThumbnails: Thumbnails,
      siNextup: NextUp
    }

  };
</script>
