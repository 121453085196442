import CONFIG from '../config';
import axios from 'axios';
import _ from 'lodash';
import defer from 'promise-defer';
import { logger } from './log.service';

export default new class SBService {
  constructor(){
    this.env = window.location.host.indexOf('localhost') > -1 ? 'local' : 'prd';
    this.hosts = {
      local: '//localhost/apps/si_if_sii-2905_internal_video-embed/public',
      prd: window.location.origin
    };
    this.deferred = new defer();
    this.response = {
      type: 'video',
      videos: null
    };
  }

  init(){
    this.requestVideo()
      .then(this.assignVideo.bind(this));

    return this.deferred.promise;
  }

  _assignProtocol(url){
    return (window.location.protocol) + '//' + (url.replace(/(^\w+:|^)\/\//, ''));
  }

  _onPlaylist(response){
    const self = this;
    self.response.type = 'playlist';
    let data = response.playlists[0];

    if(CONFIG.video.sb.playlist_order !== null || CONFIG.video.sb.playlist_order !== 'default'){
      if(CONFIG.video.sb.playlist_order === 'random'){
        data.videos = _.shuffle(data.videos);
        logger.log('Playing Random Video from Playlist');
      }else if(CONFIG.video.sb.playlist_order === 'recent'){
        data.videos = _.sortBy(data.videos, v => {
          return new Date(v.created);
        }).reverse();
        logger.log('Playlist Order: By Most Recent');
      }
    }else{
      logger.log('Playlist Order: Default');
    }

    self.response.videos = _.map(data.videos, v => {
      return {
        id: 'playlist-video-id-' + v.id,
        props: {
          src: self._assignProtocol(v.url),
          poster: self._assignProtocol(v.image_lg)
        },
        title: v.title
      }
    });
    self.deferred.resolve(self.response);
  }

  assignVideo(response){
    const self = this;

    if(response.data.status === 'success'){
      let data = null;
      let videoIndex = 0;
      logger.log({
        msg: 'SB Data Received!',
        data: response.data
      });

      if(CONFIG.video.sb.video_id !== null){
        data = response.data.data;
        CONFIG.video.props.src = self._assignProtocol(data.videos[videoIndex].url);
        CONFIG.video.props.poster = self._assignProtocol(data.videos[videoIndex].image_lg);

        this.response.videos = [{
          id: 'video-id-' + CONFIG.video.sb.video_id,
          props: {
            src: CONFIG.video.props.src,
            poster: CONFIG.video.props.poster
          },
          title: data.videos[videoIndex].title
        }];
      }else{
        self._onPlaylist(response.data.data);
      }

      this.deferred.resolve(this.response);
    }else{
      if(typeof console.error !== 'undefined') {
        logger.log({
          msg: '[Video Embed] An error occur on connecting to SB API!',
          type: 'error',
          data: response.data.error
        });
      }
      this.deferred.reject(response.data.error);
    }
  }

  requestVideo(credentials) {
    const self = this;
    let url = self.hosts[self.env] + '/api/request.php?site_id='+CONFIG.video.sb.site_id;

    if(CONFIG.video.sb.video_id){
      url +='&video_id='+CONFIG.video.sb.video_id;
    }else{
      url +='&playlist_id='+CONFIG.video.sb.playlist_id;
    }

    return axios.get(url);
  }
}();
