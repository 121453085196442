<template>
  <div class="si-videoplayer__next-up">
    <div class="si-videoplayer__next-up__container">
      <div
        class="si-videoplayer__next-up__thumbnail"
        v-on:click="nextClicked"
        :style="{
          backgroundImage: 'url(' + next.video.props.poster + ')'
        }"
      ></div>
      <div
        class="si-videoplayer__next-up__body"
        v-on:click="nextClicked">
        <div class="si-videoplayer__next-up__header">
          Next Up
        </div>
        <div class="si-videoplayer__next-up__title">
          {{ next.video.title }}
        </div>
    </div>
      <button
        type="button"
        class="si-videoplayer__next-up__close-btn"
        aria-label="Close"
        v-on:click="closeNextUp"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="svg-icon svg-icon-close"
          viewBox="0 0 240 240"
          focusable="false"
        >
          <path
            d="M134.8,120l48.6-48.6c2-1.9,2.1-5.2,0.2-7.2c0,0-0.1-0.1-0.2-0.2l-7.4-7.4c-1.9-2-5.2-2.1-7.2-0.2c0,0-0.1,0.1-0.2,0.2L120,105.2L71.4,56.6c-1.9-2-5.2-2.1-7.2-0.2c0,0-0.1,0.1-0.2,0.2L56.6,64c-2,1.9-2.1,5.2-0.2,7.2c0,0,0.1,0.1,0.2,0.2l48.6,48.7l-48.6,48.6c-2,1.9-2.1,5.2-0.2,7.2c0,0,0.1,0.1,0.2,0.2l7.4,7.4c1.9,2,5.2,2.1,7.2,0.2c0,0,0.1-0.1,0.2-0.2l48.7-48.6l48.6,48.6c1.9,2,5.2,2.1,7.2,0.2c0,0,0.1-0.1,0.2-0.2l7.4-7.4c2-1.9,2.1-5.2,0.2-7.2c0,0-0.1-0.1-0.2-0.2L134.8,120z"
          ></path>
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
  export default {

    name: 'NextUp',

    props: [
      'next', 'videos'
    ],

    methods: {

      nextClicked(){
        this.$emit('onNextClicked', this.next);
      },

      closeNextUp() {
        this.$emit('onCloseNextUp', true);
      }
    }

  };
</script>
