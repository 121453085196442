<template>
  <div
    ref="slider-container"
    class="si-videoplayer__thumbnails slider">

    <div class="slider__frame">
      <ul class="slider__slides">
        <li
          :class="{
            'thumbnail': true,
            'thumbnail--active': (current.id === video.id)
          }"
          v-for="video in videos"
          :key="video.id"
          :id="'thumb__' + video.id"
          v-on:click="onThumbnailClicked(video, $event)">

          <img
            :src="video.props.poster"
            class="thumbnail__image"
          />

          <div class="thumbnail__gradient thumbnail__gradient--vertical"></div>
          <div class="thumbnail__gradient thumbnail__gradient--overlay"></div>
          <div class="thumbnail__gradient thumbnail__gradient--bottom"></div>
          <div v-if="(current.id === video.id)" class="thumbnail__item-playing">
            NOW PLAYING
          </div>
          <p
            class="thumbnail__title"
            v-if="video.title"
            :title="video.title"
            v-text="video.title"
          ></p>
        </li>
      </ul>
    </div>

    <div class="slider__prev"></div>
    <div class="slider__next"></div>
  </div>
</template>

<script>
  export default {

    name: 'Thumbnails',

    props: [
      'current', 'videos'
    ],

    data() {

      return {
        slider: null
      };
    },

    created() {
      const self = this;

      self.$parent.$on('playerReady', val => {
        if(val && self.slider === null){
          self.initSlider();
        }
      });
    },

    methods: {

      detectSwipe (el, callback){
        let startX;
        let startY;
        let dist;
        let threshold = 150;
        let allowedTime = 200;
        let elapsedTime;
        let startTime;

        el.addEventListener('touchstart', e => {
          let touchobj = e.changedTouches[0];
          dist = 0;
          startX = touchobj.pageX;
          startY = touchobj.pageY;
          startTime = new Date().getTime();
          e.preventDefault();
        }, false);

        el.addEventListener('touchmove', e => {
          e.preventDefault();
        }, false);

        el.addEventListener('touchend', e => {
          let touchobj = e.changedTouches[0];
          dist = touchobj.pageX - startX;
          elapsedTime = new Date().getTime() - startTime;
          let swiperightBol = (elapsedTime <= allowedTime && dist >= threshold && Math.abs(touchobj.pageY - startY) <= 100);
          callback(swiperightBol);
          e.preventDefault();
        }, false)
      },

      initSlider() {
        const self = this;

        // self.detectSwipe(self.$refs['slider-container'], to => {
        //   console.log(to);
        // });

        // self.$refs['slider-container'].addEventListener('after.lory.init', () => {
        //
        // });

        self.slider = window.lory(self.$refs['slider-container'], {
          classNameFrame: 'slider__frame',
          classNameSlideContainer: 'slider__slides',
          classNamePrevCtrl: 'slider__prev',
          classNameNextCtrl: 'slider__next',
          classNameActiveSlide: 'active',
          rewind: false,
          infinite: false,
          rewindPrev: false,
          rewindOnResize: true
        });

        window.addEventListener('resize', () => {
          self.$emit('onThumbnailClicked', self.videos[0]);
        });
      },

      onThumbnailClicked(v, e) {
        e.preventDefault();
        e.stopPropagation();
        this.$emit('onThumbnailClicked', {
          v: v,
          e: e
        });
      }
    }
  };
</script>
