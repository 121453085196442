import Vue from 'vue';
import App from './App.vue';
import CONFIG from './config';
import {GAtracker} from './services/ga-tracker.service';
import _ from 'lodash';
import { iframeResizer } from 'iframe-resizer';
import { logger } from './services/log.service';

Vue.config.productionTip = false;

// Our Main SASS file with dependencies
import './sass/index.scss';

// Set Iframe Resizer
iframeResizer({
  log: false
});

// Get Query Parameters from URL
const parse = val => {
  let result = false;
  let tmp = [];
  const query = location.search.substr(1).split('&');

  query.forEach(item => {
    tmp = item.split('=');
    if (tmp[0] === val) {
      result = decodeURIComponent(tmp[1]);
    }
  });
  return result;
};
const convertToBoolean = value => {
  switch(value){
    case true:
    case 'true':
    case 'TRUE':
    case 1:
    case '1':
    case 'ON':
    case 'on':
    case 'YES':
    case 'yes':
      return true;
    case false:
    case 'FALSE':
    case 'false':
    case 0:
    case '0':
    case 'OFF':
    case 'off':
    case 'no':
    case 'NO':
      return false;
    default:
      return value;
  }
};
const inIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};
const applyParams = () => {
  let allowedProperties = [
    'autoplay',
    'controls',
    'currentTime',
    'defaultMuted',
    'loop',
    'muted',
    'src',
    'poster',
    'playsinline'
  ];
  _.each(allowedProperties, p => {
    if(parse(p)){
      if(p === 'src' && parse(p).indexOf('//cdn.springboardplatform.com/') === -1){
        if(typeof console.error !== 'undefined'){
          console.error('[Video Embed] Validator Failed! \nTried to play video that is not being hosted by Springboard');
        }
        return;
      }
      CONFIG.video.props[p] = convertToBoolean(parse(p));
      if(p === 'currentTime'){
        CONFIG.video.props[p] = Number(CONFIG.video.props[p]);
      }
    }
  });
  if(parse('debug')){
    CONFIG.debug = convertToBoolean(parse('debug'));
  }
  if(parse('ci')){
    CONFIG.video.ci =  parse('ci');
  }
  if(parse('id')){
    CONFIG.video.id =  parse('id');
  }
  if(inIframe() && window.innerWidth){
    CONFIG.video.size.width =  window.innerWidth;
  }
  if(inIframe() && window.innerHeight){
    CONFIG.video.size.height =  window.innerHeight;
  }
  if(parse('width')){
    CONFIG.video.size.width =  parse('width');
  }
  if(parse('height')){
    CONFIG.video.size.height =  parse('height');
  }
  if(parse('gaId')){
    CONFIG.analytics.ga.id =  parse('gaId');
  }
  // Ad Tag Params
  if(parse('iu')){
    CONFIG.video.ad.iu =  parse('iu');
  }
  if(parse('cust_params')){
    CONFIG.video.ad.cust_params =  parse('cust_params');
    if (
      (CONFIG.video.ad.cust_params.indexOf('vast') === -1)
      && (CONFIG.video.ad.cust_params.indexOf('kw=') > -1)
    ) {
      CONFIG.video.ad.cust_params += CONFIG.video.ad.cust_params + encodeURIComponent(',vast');
    }
  } else {
    CONFIG.video.ad.cust_params = encodeURIComponent('kw=vast');
  }
  if(parse('t')){
    CONFIG.video.ad.t =  parse('t');
  }
  // SB API Params
  if(parse('site_id')){
    CONFIG.video.sb.site_id =  parse('site_id');
  }
  if(parse('playlist_id')){
    CONFIG.video.sb.playlist_id =  parse('playlist_id');
  }
  if(parse('video_id')){
    CONFIG.video.sb.video_id =  parse('video_id');
  }
  if(parse('playlist_order')){
    CONFIG.video.sb.playlist_order =  parse('playlist_order');
  }
  if(parse('thumbnails')){
    CONFIG.video.sb.thumbnails =  convertToBoolean(parse('thumbnails'));
  }
};

//
// Create Vue Instance
//
new Vue({

  render: h => h(App),

  data() {
    return {
      gaScript: `(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
          (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
          m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
          })(window,document,'script','//www.google-analytics.com/analytics.js','ga');`
    };
  },

  mounted() {
    const bodyTag = document.getElementsByTagName('body')[0];

    if (CONFIG.analytics.ga.id !== null) {
      const gaScript = document.createElement('script');
      gaScript.type ='text/javascript';
      gaScript.textContent = this.gaScript;
      bodyTag.appendChild(gaScript);
      GAtracker.init();
    }
  },

  created() {

    // Apply parameters
    applyParams();

    // Set log service
    logger.setDebug(CONFIG.debug);
  }
}).$mount('#app');
