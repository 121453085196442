class Logger {
  constructor() {
    this._debugMode = false;
    this._isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
    this._isFirefox = /firefox/i.test(navigator.userAgent);
  }

  setDebug(debug) {
    if(debug){
      this._debugMode = true;
    }

    // window.og.debugMode = debugMode;

    this.log({
      msg: 'DEBUG mode: ' + (this._debugMode ? 'on' : 'off')
    });
  }

  log(data) {
    if(
      !this._debugMode ||
      typeof window.console === 'undefined' ||
      typeof data === 'undefined'
    ){
      return;
    }

    let obj  = {
      msg: typeof data === 'string' ? data : data.msg,
      type: typeof data !== 'string' && typeof data.type !== 'undefined' ? data.type : 'info',
      data: typeof data !== 'string' && typeof data.data !== 'undefined' ? data.data : ''
    };
    let msgTpl = '### [Video Embed]';
    let tpl = {
      error: {
        msg: ' - ERROR! ',
        style: 'background: #FF0000; color: #FFFFFF;'
      },
      info: {
        msg: ' ',
        style: 'background: #2e4ec1; color: #FFFFFF;'
      },
      success: {
        msg: ' - SUCCESS! ',
        style: 'background: #00e500; color: #FFFFFF;'
      },
      warning: {
        msg: ' - WARNING! ',
        style: 'background: #FFA500; color: #FFFFFF;'
      },
      event: {
        msg: '     ',
        style: 'background: #00FF00; color: #000; border-left: 16px solid #008C00;'
      },
      on: {
        msg: '     ',
        style: 'background: #FFFF00; color: #000; border-left: 16px solid #B2B200;'
      }
    };

    if(this._isChrome || this._isFirefox){
      let c = decodeURI('%25');
      console.log(c+'c ' + (msgTpl + tpl[obj.type].msg + '\n> ' + obj.msg), tpl[obj.type].style, obj.data);
      return;
    }

    console.log(msgTpl + obj.msg, obj.data);
  }
}

export let logger = new Logger();
