const CONFIG = {

  debug: false,

  //
  // Analytics
  //
  analytics: {

    // Google Analytics
    ga: {
      id: null,
      category: 'Videos'
    }
  },

  //
  // Props
  //
  video: {
    ci: '',
    id: null,
    ad: {
      tag: 'https://pubads.g.doubleclick.net/gampad/ads?sz=150x150&ciu_szs=300x250&impl=s&gdfp_req=1&env=vp&output=xml_vast2&unviewed_position_start=1&url=&nofb=1&ad_rules=0',
      iu: null,
      cust_params: null,
      t: null
    },
    props: {
      src: null
    },
    size: {
      height: 315,
      width: 560
    },
    sb: {
      site_id: null,
      playslist_id: null,
      video_id: null,
      playlist_order: null,
      thumbnails: false
    },
    type: 'video',
    playlist: [],
    visible: true
  }
};

export default CONFIG;
